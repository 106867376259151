<template>
  <div class="live_detail">
    <div class="left_main">
      <div class="title">
        <div class="title_content">
          <span class="course_type">
            {{ detailInfo.gradeName }} {{ detailInfo.subjectName }}
          </span>
          <span class="course_name">{{ detailInfo.title }}</span>
        </div>
        <div class="views_num">
          <span class="nums">{{ detailInfo.numberOfWatch }}人观看</span>
          <el-button size="mini" type="warning" v-show="false">分享</el-button>
          <span class="expand">
            <i
              @click="changeC"
              :class="
                isCollapse ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'
              "
            ></i>
          </span>
        </div>
      </div>
      <div v-if="this.$route.params.isFake" class="main main-video">
        <video :src="detailInfo.videoUrl" autoplay muted loop></video>
      </div>
      <div v-else class="main">
        <TempPlayer
          v-if="this.$route.params.id === 'undefined'"
          :ShowBusBar="true"
        ></TempPlayer>
        <Player
          v-if="this.$route.params.id !== 'undefined'"
          :url="videourl"
          :isLive="true"
        ></Player>
        <img v-if="Livefinished" src="@/assets/images/live_end.png" alt />
      </div>
      <div class="footer">
        <div class="teacher">
          <div class="infos">
            <div class="img">
              <img
                :src="
                  detailInfo.lecturerPhoto
                    ? detailInfo.lecturerPhoto
                    : defaultImg
                "
                alt
              />
            </div>
            <div class="name">
              <div>{{ detailInfo.lecturerName }}</div>
              <div>140人已关注</div>
            </div>
          </div>
          <div v-show="false" class="cross_line"></div>
          <el-button
            v-show="false"
            @click="attentions"
            class="focus"
            size="mini"
            type="warning"
          >
            {{ isFocus ? "已关注" : "关注" }}
          </el-button>
        </div>
        <div class="operate">
          <span @click="courseDescDialogVisible = true">
            <i class="el-icon el-icon-info"></i>课程描述
          </span>
          <span v-if="false" @click="sourceDownloadDialogVisible = true">
            <i class="el-icon el-icon-download"></i>资料下载
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="false"
      :style="{ width: isCollapse ? '0px' : '' }"
      class="right_pad"
    >
      <div class="tabs" v-show="!isCollapse">
        <div
          @click="tabsActive = 0"
          :class="tabsActive === 0 ? 'active' : ''"
          class="tabs_item"
        >
          讨论
        </div>
        <div class="cross_line"></div>
        <div
          @click="tabsActive = 1"
          :class="tabsActive === 1 ? 'active' : ''"
          class="tabs_item"
        >
          笔记
        </div>
      </div>
      <div class="content_list">
        <el-scrollbar v-if="tabsActive === 0">
          <div
            v-for="(item, index) in commentList"
            :key="index"
            class="comment_item"
          >
            <div class="user_infos">
              <div class="name flex">
                <img :src="item.img" alt />
                <span>{{ item.name }}</span>
              </div>
              <div class="time">
                <span>{{ item.time }}</span>
                <i class="el-icon el-icon-delete"></i>
              </div>
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
        </el-scrollbar>
        <el-scrollbar v-else-if="tabsActive === 1">
          <div class="note_item">111</div>
        </el-scrollbar>
      </div>

      <div class="comment">
        <div class="input">
          <el-input
            v-model="commentVal"
            placeholder="说点什么跟大家一起讨论讨论吧~"
            type="textarea"
            maxlength="200"
            :rows="6"
            :class="isCollapse ? 'paddnone' : ''"
          ></el-input>
        </div>
        <div class="bar" v-show="!isCollapse">
          <span>{{ commentVal.length }}/200</span>
          <el-button size="mini" type="warning">发表讨论</el-button>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="courseDescDialogVisible"
      custom-class="course_desc"
      title="课程描述"
      width="30%"
    >
      <span>{{ describe }}</span>
    </el-dialog>

    <el-dialog
      v-model="sourceDownloadDialogVisible"
      title="资料下载"
      custom-class="source_download"
      width="50%"
    >
      <el-scrollbar height="500px">
        <div class="source_list">
          <div
            v-for="(item, index) in sourceList"
            :key="index"
            class="source_item"
          >
            <div class="source_infos">
              <img :src="item.img" alt />
              <span>{{ item.name }}</span>
            </div>
            <div class="source_btn">
              <el-button size="mini" type="primary">预览</el-button>
              <el-button size="mini" type="warning">下载</el-button>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>

    <el-dialog
      custom-class="attention"
      title
      :show-close="false"
      v-model="attentionDialogVisible"
      width="30%"
    >
      <div class="content">
        <img src="../../../assets/images/成功.png" alt />
        <div>关注成功</div>
        <div>新的直播预告将第一时间为您推送哦~！</div>
        <el-button @click="attentionDialogVisible = false" type="warning" round>
          我知道了
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AuthLive from "@/api/live";
import TempPlayer from "../components/Player.vue";
import Player from "@/components/Player/index.vue";
import CommonApi from "@/api/common.ts";
import { getAssetsFile } from "@/scripts/utils";
export default defineComponent({
  components: {
    Player,
    TempPlayer,
  },
  data() {
    return {
      isFake: false,
      defaultImg: require("@/assets/imgs/default_avatar.png"),
      detailInfo: {},
      commentList: [],
      sourceList: [
        {
          img: require("../../../assets/images/ppt.png"),
          name: "学习任务的名称如马克思主义理论等",
        },
        {
          img: require("../../../assets/images/word.png"),
          name: "学习任务的名称如马克思主义理论等",
        },
        {
          img: require("../../../assets/images/ppt.png"),
          name: "学习任务的名称如马克思主义理论等",
        },
        {
          img: require("../../../assets/images/word.png"),
          name: "学习任务的名称如马克思主义理论等",
        },
        {
          img: require("../../../assets/images/ppt.png"),
          name: "学习任务的名称如马克思主义理论等",
        },
        {
          img: require("../../../assets/images/word.png"),
          name: "学习任务的名称如马克思主义理论等",
        },
      ],
      isFocus: false,
      tabsActive: 0,
      commentVal: "",
      courseDescDialogVisible: false,
      sourceDownloadDialogVisible: false,
      attentionDialogVisible: false,
      isCollapse: false,
      Livefinished: false,
      videourl: "", //视频地址
      describe: "", //课程描述
      isLive: false,
    };
  },
  created() {
    if (this.$route.params.id !== "undefined") {
      this.Geturl();
    } else if (this.$route.params.isFake) {
      this.loadAssets();
    }
    // else {
    //   this.videourl =
    //     "http://10.10.40.23/live/22052617260210795401201084480948.flv";
    // }
  },
  methods: {
    // 虚假数据
    async loadAssets() {
      const res = await CommonApi.GetDocData("live_detail");
      console.log(getAssetsFile(res.data.lecturerPhoto));
      const imgSuffix = res.data.lecturerPhoto.split(".")[1];
      const vdSuffix = res.data.videoUrl.split(".")[1];
      this.detailInfo = {
        ...res.data,
        lecturerPhoto: getAssetsFile(res.data.lecturerPhoto, imgSuffix),
        videoUrl: getAssetsFile(res.data.videoUrl, vdSuffix),
      };
      this.describe = res.data.describe;
    },
    //获取视频地址
    async Geturl() {
      if (this.$route.params.id === "555555555XXX555555555555444444") {
        this.isLive = false;
        this.$nextTick(function () {
          this.videourl = "https://www.fjrh.cn/video/2205_2020_11_04_4.MP4";
        });
      } else {
        this.isLive = true;
        const res = await AuthLive.viewLive({
          liveId: this.$route.params.id,
        });
        if (res.data.state === 2) {
          this.Livefinished = true;
        }
        let temp = {};
        this.detailInfo = res.data ? res.data : temp;
        if (res.data.state !== 2) {
          await AuthLive.joinLive({ liveId: this.$route.params.id });
          const resp = await AuthLive.listParticipant({
            liveId: this.$route.params.id,
          });
          this.commentList = [];
          resp.data.list.forEach((item) => {
            const obj = {
              img: item.avatar,
              name: item.name,
              time: item.gmtJoin,
              text: "",
            };
            this.commentList.push(obj);
          });
          this.videourl = res.data.pullURL;
          this.describe = res.data.description;
        }
      }
    },
    attentions() {
      if (!this.isFocus) {
        this.attentionDialogVisible = true;
        this.isFocus = true;
      } else {
        this.isFocus = false;
      }
    },
    changeC() {
      this.isCollapse = !this.isCollapse;
    },
  },
});
</script>

<style lang="scss">
.live_detail {
  width: 100%;
  height: 100%;
  display: flex;
  .left_main {
    flex: 1;
    height: 100%;
    .title {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      color: #ffffff;
      background: #000000;
      .title_content {
        .course_type {
          padding: 8px;
          border: 1px solid #f27200;
          color: #f27200;
          font-size: 12px;
          border-radius: 4px;
        }
        .course_name {
          font-size: 16px;
          margin-left: 10px;
        }
      }
      .views_num {
        .nums {
          margin-right: 10px;
        }
        .expand {
          display: inline-block;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background-color: #1d222e;
          color: #666666;
          cursor: pointer;
          margin-left: 20px;
        }
      }
    }
    .main {
      height: calc(100% - 140px);
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .main-video {
      background: black;
      video {
        width: 100%;
        height: 100%;
      }
    }
    .footer {
      height: 80px;
      background: #000000;
      color: #fff;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .teacher {
        display: flex;
        align-items: center;
        .infos {
          display: flex;
          .img {
            margin-right: 10px;
            width: 45px;
            height: 45px;
            background: white;
            border-radius: 50%;
            img {
              border-radius: 50%;
              width: 100%;
              height: 100%;
            }
          }
          .name {
            div:nth-child(1) {
              color: #ffffff;
              height: 20px;
              line-height: 20px;
              font-size: 14px;
            }
            div:nth-child(2) {
              color: #999999;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
            }
          }
        }
        .cross_line {
          width: 1px;
          height: 40px;
          margin: 0 20px;
          background-color: #999999;
        }
        .focus {
          height: 24px;
        }
      }
      .operate {
        span:nth-child(1) {
          cursor: pointer;
          margin-right: 20px;
          font-size: 14px;
          color: #ffffff;
          i {
            font-size: 16px;
            margin-right: 5px;
            color: #fefefe;
          }
        }
        span:nth-child(2) {
          cursor: pointer;
          font-size: 14px;
          color: #ffffff;
          i {
            font-size: 16px;
            margin-right: 5px;
            color: #fefefe;
          }
        }
      }
    }
  }
  .right_pad {
    width: 320px;
    height: 100%;
    background: #151923;
    // transition: width 1s;
    // -moz-transition: width 1s; /* Firefox 4 */
    // -webkit-transition: width 1s; /* Safari and Chrome */
    // -o-transition: width 1s; /* Opera */
    .tabs {
      height: 40px;
      border-bottom: 1px solid #2e3442;
      display: flex;
      align-items: center;
      .tabs_item {
        width: calc((100% - 1px) / 2);
        color: #999999;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
      }
      .active {
        color: #ff8a00;
      }
      .cross_line {
        height: 20px;
        width: 1px;
        background-color: #2e3442;
      }
    }
    .content_list {
      height: calc(100% - 241px);
      overflow: hidden;
      .el-scrollbar {
        height: calc(100% + 8px);
        .el-scrollbar__wrap {
          overflow: scroll;
          .el-scrollbar__view {
            .comment_item {
              width: 100%;
              padding: 0 20px;
              margin-top: 20px;
              border-bottom: 1px solid #666666;
              box-sizing: border-box;
              .user_infos {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                .name {
                  line-height: 40px;
                  color: #ff8a00;
                  font-size: 14px;
                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                  }
                  span {
                    margin-left: 10px;
                  }
                }
                .time {
                  color: #666666;
                  font-size: 12px;
                  i {
                    font-size: 14px;
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }
              }
              .text {
                color: #666666;
                font-size: 14px;
                margin-bottom: 20px;
              }
            }
            .note_item {
              color: #fff;
              width: calc(100% - 40px);
              margin: 0 20px;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .comment {
      background-color: #1d212a;
      height: 200px;
      .input {
        height: calc(100% - 60px);
        .el-textarea {
          .el-textarea__inner {
            border-radius: 0px;
            background-color: #1d212a;
            border: 0px;
            padding: 20px;
            font-size: 12px;
            resize: none;
          }
        }
        .paddnone {
          .el-textarea__inner {
            padding: initial;
          }
        }
      }
      .bar {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        color: #999999;
        font-size: 12px;
      }
    }
  }
  .el-button--mini {
    padding: 5px 11px;
    border-radius: 1px;
  }
  .el-button--warning {
    background-color: #f27200;
    border-color: #f27200;
  }
  .course_desc {
    .el-dialog__header {
      border-bottom: 1px solid #dddddd;
    }
    .el-dialog__body {
      padding: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .source_download {
    .el-dialog__header {
      border-bottom: 1px solid #dddddd;
    }
    .el-dialog__body {
      padding: 20px;
      padding-top: 0px;
      .source_list {
        .source_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #dddddd;
          padding: 20px 0;
          padding-right: 20px;
          .source_infos {
            line-height: 60px;
            img {
              width: 60px;
              height: 60px;
            }
            span {
              color: #333333;
              font-size: 20px;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  .attention {
    .el-dialog__header {
      padding: 0px;
    }
    .el-dialog__body {
      .content {
        text-align: center;
        div:nth-child(2) {
          color: #2db557;
          font-size: 28px;
          margin-top: 30px;
        }
        div:nth-child(3) {
          color: #666666;
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 30px;
        }
        .el-button {
          min-height: 32px;
        }
        .is-round {
          padding: 8px 31px;
        }
      }
    }
  }
}
</style>
